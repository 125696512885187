.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.Container {
    width: 1140px;
    margin: 0 auto;
}
.Row{
    display: flex;
    flex-wrap: wrap;
}
.signup_MainDiv{
    width: 100%;

}
.Header{
    background: #FF4C00;;
    padding: 0px 51px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    font-weight: 600;
    text-decoration: none;
    color:  #fff;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.my_DashboardMainArea{
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
    margin-top: 16px;
}
.my_DashboardNam{
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.my_DashboarHeading{
    font-size: 25px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.my_DashboarSubHeading{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.my_DashboardNum{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.display_flex{
    display: flex;
    gap: 8px;
}
.boardNumGroup{
    display: flex;
    padding-right: 16px;
    align-items: center;
    gap: 7px;
    border-radius: 32px;
    border: 1px solid #D9D9D9;
    background: #FFF;
}
.boardNumGroup__Co{
    display: flex;
    padding: 8px;
    align-items: center;
    border-radius: 24px;
    background: #174FB9;
}
.my_DashboardNumber{
    font-size: 17px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.oreng_Colr{
    background: #FF4C00;
}
.My_DashboardLink{
    display: flex;
    width: 100%;
    justify-content: center;
}
.My_DashboardLinkGroup{
    display: flex;
    align-items: center;
    gap: 8px;
}
.My_DashboardLinkGroupTex{
    font-size: 14px;
    color: #174FB9;
    font-weight: 600;
    margin: 0;
}
.class_SubjectMainArea{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 8px;
}
.class_SubjectText{
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin: 0;
}
.class_SubjectCusRow{
    display: flex;
}
.margin_right{
    margin-right: 8px;
}
.class_SubjectButtom{
    display: flex;
    padding: 6px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    gap: 16px;
}
.class_SubjectButtom svg{
    color: #909090;
    width: 20.4px;
    height: 23px;
}
.total_ScoreMainSec{
    margin-top: 8px;
}
.total_ScoreCusRow{
    display: flex;
    justify-content: space-between;
}
.total_ScoreCard{
    width: 30%;
    padding: 0 8px;
}
.total_ScoreCardUnder{
    display: flex;
    padding: 17px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
    width: 100%;
    height: 100%;
}
.total_ScoreCardUnderText{
    font-size: 12px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.total_ScoreCardUnderNumber{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.tex_NumGrop{
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    justify-content: space-between;
}
.Valu_UpDownMainSec{
    margin-top: 4px;
}
.Valu_UpDownBig_Sec{
    width: 70%;
    padding: 8px;
}
.Valu_UpDownBig_SecUnder{
    display: flex;
    padding: 16px 32px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: #FFF;
}
.marks_Area{
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-right: 1px solid #C6C6C5;
    padding-right: 24px;
}
.marks_AreaTitel{
    font-size: 15px;
    color: #4B494E;
    font-weight: 400;
    margin: 0;
    transform: rotate(-90deg);
}
.marks_AreaNumber{
    font-size: 11px;
    color: #949294;
    font-weight: 500;
    margin: 0;
    text-align: right;
}
.valu_UpDownLabelArea{
    display: flex;
    padding: 0px 64px;
    justify-content: center;
    align-items: flex-end;
    gap: 80px;
    align-self: stretch;
}
.green_Label{
    display: flex;
    height: 120.667px;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    background: #2EE700;
}
.blue_Label{
    display: flex;
    height: 160.667px;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    background: #324DDD;
}
.oreang_Label{
    display: flex;
    height: 180.667px;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    background: #FF5C00;
}
.valu_UpDownLabelText{
    font-size: 12px;
    color: #272A3E;
    font-weight: 500;
    text-align: center;
}
.LabelendingSec{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 56px;
    align-self: stretch;
    border-top: 1px solid #C6C6C5;
}
.endingGrop{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.green_ColrDiv{
    width: 41px;
    height: 8px;
    background: #2EE600;
}
.endingText{
    font-size: 12px;
    color: #4B494E;
    font-weight: 700;
    margin: 0;
}
.blue_ColrDiv{
    width: 41px;
    height: 8px;
    background: #324DDD;
}
.oreng_ColrDiv{
    width: 37px;
    height: 8px;
    background: #FF5C00;
}
.table_Sec{
    border-radius: 8px;
    background: #FFF;
    padding: 4px;
    margin-top: 20px;
}
.table_Sec tr{
    border-bottom: 1px solid #D9D9D9;
}
.th_HedingCustom{
    font-size: 13px;
    color: #4B4B4B;
    font-weight: 500;
}
.title_Vertical{
    font-size: 12px;
    color: #606060;
    font-weight: 500;
}
.title_VerticalViwMore{
    font-size: 13px;
    color: #191D63;
    font-weight: 500;
}
.smaLL_Ptag {
    font-size: 10px;
    color: #909090;
    font-weight: 500;
    margin: 0;
}
.blue_ColrTex{
    color: #191D63;
}
.align_Center {
    text-align: center;
}
.align_Right {
    text-align: right;
}
.class_SubjectSmall_Sec{
    width: 30%;
    padding: 8px;
}
.ClassArea{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}
.ClassHeding{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.Class_CostUL{
    margin: 0;
    padding: 0;
    width: 100%;
}
.Class_CostUL li{
    display: flex;
    list-style: none;
    padding: 6px 0;
    justify-content: space-between;
}
.Class_CostL_TtitelCL{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.CostL_TtitelPG{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.SubjectArea{
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    margin-top: 26px;
}
.SubjectHeding{
    font-size: 14px;
    color: #2D2D2D;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 8px;
}
.Subject_CostUL{
    margin: 0;
    padding: 0;
    width: 100%;
}
.Subject_CostUL li{
    display: flex;
    list-style: none;
    padding: 14px 0;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
    position: relative;
}
.Subject_CostL_TtitelSUB_Cgreen{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
    padding-left: 8px;
}
.Subject_CostL_TtitelSUB_Cyello{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
    padding-left: 8px;
}
.Subject_CostL_TtitelSUB_Cblue{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
    padding-left: 8px;
}
.Subject_CostL_TtitelSUB_Cpink{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
    padding-left: 8px;
}
.Subject_CostL_TtitelSUB_Cgreen::after{
    background: #0F883F;
    bottom: 0;
    content: "";
    height: 4px;
    left: -12px;
    position: absolute;
    transform: translate(-50%);
    width: 28px;
    transform: rotate(-90deg);
    top: 21px;
    border-radius: 2px;
}
.Subject_CostL_TtitelSUB_Cyello::after{
    background: #FF8A00;
    bottom: 0;
    content: "";
    height: 4px;
    left: -12px;
    position: absolute;
    transform: translate(-50%);
    width: 28px;
    transform: rotate(-90deg);
    top: 21px;
    border-radius: 2px;
}
.Subject_CostL_TtitelSUB_Cblue::after{
    background: #00A3FF;
    bottom: 0;
    content: "";
    height: 4px;
    left: -12px;
    position: absolute;
    transform: translate(-50%);
    width: 28px;
    transform: rotate(-90deg);
    top: 21px;
    border-radius: 2px;
}
.Subject_CostL_TtitelSUB_Cpink::after{
    background: #A1DA00;
    bottom: 0;
    content: "";
    height: 4px;
    left: -12px;
    position: absolute;
    transform: translate(-50%);
    width: 28px;
    transform: rotate(-90deg);
    top: 21px;
    border-radius: 2px;
}
.ViewLink{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.ViewLinkGroup{
    display: flex;
    align-items: center;
    gap: 8px;
}
.ViveLinkGroupTex{
    font-size: 12px;
    color: #174FB9;
    font-weight: 600;
    margin: 0;
}
.ViewLinkGroup img{
    width: 7px;
    height: 24px;
}
.noData_FoundSec{
    display: flex;
    background: #fff;
    height: 300px;
    padding: 0;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}
.noData_Found_SecUnder{
    align-content: center;
    opacity: .4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.noData_Found_SecUnder img{
    margin-bottom: 40px;
    width: 120px;
}
.noData_Found_SecUnder p{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}