.Container {
    width: 1024px;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.MainDiv_Area{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
    margin-top: 16px;
    min-height: 542px;
}
.general_TextAroSecTitel {
    font-size: 16px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 16px;
    width: 100%;
}
.Tex_InputChackGrop {
    display: flex;
    gap: 8px;
}
.general_TextAroSec {
    display: flex;
    align-items: center;
    gap: 16px;
}
.general_TextAroSecTitel1 {
    font-size: 17px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.option_InputGroup{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 8px;
    margin-top: 16px;
}
.chack_Box_SmallDiv{
    display: flex;
    padding: 0 8px 8px 8px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.chack_Box_SmallDiv p{
    font-size: 16px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.checkbox_Flex{
    display: flex;
    gap: 8px;
}
.chackBox_labelTex {
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.input_Button_BigDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}
.accomplishment_inputTyWhiteColer {
    background: #FFF;
    width: 100%;
    padding: 16px;
    font-weight: 400;
    color: #909090;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
}
.chackBoxButton {
    display: flex;
    width: 130px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #174FB9;
    border: none;
    font-size: 12px;
    color: #FFF;
    font-weight: 600;
    cursor: pointer;
}
.ending_SaveBU1 {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    margin-bottom: 16px;
    margin-top: 16px;
}
.ending_SaveSec {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid #D9D9D9;
    padding-top: 16px;
}
.ending_SaveBU {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    margin-bottom: 8px;
}













































@import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&family=Barlow+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;300;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;800&display=swap');
a{
    text-decoration: none !important;
}
*, body {
    font-family: 'Noto Sans', sans-serif;
}

.Row{
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -15px; */
}
.signup_MainDiv{
    width: 100%;

}
.Header{
    background: #FF4C00;;
    padding: 0px 51px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    /* display: block; */
    font-weight: 600;
    text-decoration: none;
    color:  #fff;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.Button_WhiteColerDiv{
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #E6ECF0;
}
.general_TextAroSecTitel2{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.accomplishment_FormField {
    padding: 8px 0;
    width: 100%;
}
.accomplishment_Label {
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    padding-bottom: 8px;
    padding-left: 2px;
}
.accomplishment_inputTy {
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    width: 100%;
    padding: 16px;
    font-weight: 400;
    color: #000;
    font-size: 14px;
    outline: none;
    height: 40px;
}
form{
    width: 100%;
}
.chackBox_BUSec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    margin-top: 8px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 16px;
}
.chackBox-Text {
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    padding-right: 8px;
}
.width-30{
    width: 30%;
}
.login_ErrorM{
    color: #f44336;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    margin-top: 5px;
    padding-left: 6px;
}
.login_ErrorMB{
    color: #f44336;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    margin-top: 5px;
    padding-left: 6px;
}