.Header{
    border-bottom: 1px solid #EAEAEA;
}

.Container {
    width: 1024px;
    margin: 0 auto;
}

.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    /* display: block; */
    font-weight: 600;
    text-decoration: none;
    color: #000;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}

.HeadLoginBU{
    font-size: 15px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 8px 16px;
    width: 144px;
    border-radius: 8px;
    border: none;
    background: #FF7A00;
    margin-left: 10px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
}