.Container {
    width: 1024px;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.AccomplishmentSec{
    display: flex;
    /* width: 944px;
    height: 60px; */
    padding: 16px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    margin-top: 16px;
    margin-bottom: 8px;
}
.AccomplishmentSec p{
    font-size: 18px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.FormBoxArea{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}
.TextAroSecTitel{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.chackBox_BUSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    margin-top: 8px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 16px;
}
.InputChackGrop{
    display: flex;
    gap: 8px;
}
.checkBoxLabel{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    padding-right: 8px;
}
.checkBoxValueText{
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.dateInput_Sec{
    display: flex;
    align-items: flex-start;
}
.dateInput_Under{
    display: block;
    align-items: flex-start;
}
.dateInput_Under1{
    display: flex;
    align-items: flex-start;
    width: 305px;
    justify-content: space-between;
}
.dateInput_UnderNumInut{
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 14px;
    color: #909090;
    font-weight: 600;
    width: 91px;
}
.w_125{
    width: 125px;
}
.wd_100{
    width: 108px;
}
.dateInput_Sec1{
    display: flex;
    align-items: flex-start;
    gap: 32px;
}
.checkBox_BUSec2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    margin-top: 8px;
    padding-bottom: 16px;
}
.code_ButtomGroup{
    display: flex;
    gap: 16px;
}
.inputPW{
    display: flex;
    width: 191px;
    padding: 8px 16px;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 14px;
    color: #909090;
    font-weight: 600;
    margin-bottom: 8px;
    outline: none;
}
.chackBoxButton{
    display: flex;
    width: 192px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #174FB9;
    border: none;
    font-size: 12px;
    color: #FFF;
    font-weight: 600;
}
.SaveSec{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.SaveBU{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    margin-top: 16px;
}
.TextTy{
    display: flex;
    width: 192px;
    padding: 8px 16px;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    outline: none;
}
.pipi{
    padding: 0 !important;
    margin-left: 20px;
}
.pipi select{
    background-color: #fff !important;
    border: 1px solid #E6E6E6 !important;
}
.checkBoxValueTextNew{
    margin-left: 0 !important;
}
.checkBoxValueTextNew span{
    padding: 0;
}
.login_ErrorM{
    color: #f44336;
    font-size: 13px;
    margin: 0;
    margin-top: 5px;
    padding-left: 6px;
}
.login_ErrorMT{
    color: #f44336;
    font-size: 13px;
    margin: 0;
    margin-top: 5px;
    padding-left: 6px;
    margin-bottom: 11px;
}
.borderNone{
    outline: none;
}