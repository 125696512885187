.Container {
    width: 1024px;
    margin: 0 auto;
}
.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.Header{
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 18px;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    font-weight: 600;
    text-decoration: none;
    color: #000;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.HeadLoginBU{
    font-size: 15px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 8px 16px;
    width: 144px;
    border-radius: 8px;
    border: none;
    background: #FF7A00;
    margin-left: 10px;
    cursor: pointer;
    overflow: hidden;
}
.Your_ScoresMainArea{
    display: flex;
    align-items: flex-end;
    gap: 16px;
    margin: 0 2px;
}
.Your_ScoresNameSec{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}
.Your_ScoresNameSecHalf{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}
.NameSecHalfNam{
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin: 0;
}
.NameSecHalfNamSub{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    margin: 0;
}
.Your_ScoresNameSecTimerSec{
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: #FFE9E9;
}
.Your_ScoresNameSecTimerSec img{
    width: 32px;
    height: 32px;
}
.TimerSecBigTitel{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.TimerSecSmallTitel{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.your_LeagueRank{
    display: flex;
    width: 224px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    flex-shrink: 0;
    margin-bottom: 0px;
}
.your_LeagueRankHalf{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.your_LeagueRankHalfHeding{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.your_LeagueRankHalfGroup{
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: #FFF;
}
.your_LeagueRankHalfGroupNubText{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.your_LeagueRankHalfGroupNamText{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.Your_ScoresHalf{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 12px;
}
.Your_ScoresHalfTitel{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.Your_ScoresFaild{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.Your_ScoresFaildText{
    font-size: 12px;
    color: #FF7A00;
    font-weight: 600;
    margin: 0;
}
.Your_ScoresFaildNum{
    font-size: 12px;
    color: #FF7A00;
    font-weight: 500;
    margin: 0;
}
.num_sterGrop{
    display: flex;
    align-items: center;
    gap: 9px;
}
.Green__StarColr{
    color: #009861;
}
.boderArea{
    width: 1px;
    height: 230px;
    background: #D9D9D9;
}
.my_DashboardSec{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}
.my_DashboardSecTxt{
    font-size: 12px;
    color: #06F;
    font-weight: 600;
    margin: 0;
}
.my_DashboardSec svg{
    width: 20px;
    height: 17px;
    color: #06F;
}
.viewAll_Sec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
    margin: 0 8px;
}
.viewAll_LeftSideText{
    font-size: 15px;
    color: #000;
    font-weight: 500;
    margin: 0;
}
.viewAll_RightSideText{
    font-size: 12px;
    color: #000;
    font-weight: 600;
    margin: 0;
}
.disflex{
    display: flex;
    align-items: baseline;
}
.disflex svg{
    width: 20px;
    color: #000 !important;
    text-decoration: none !important;
}
.title_Subjt_EnddateStatusAreaSec{
    margin-top: 16px;
}
table{
    width: 100%;
}
.th_HedingCustom{
    font-size: 16px;
    color: #4B4B4B;
    font-weight: 600;
}
.align_Right{
    text-align: right;
}
.align_Center{
    text-align: center;
}
.smaLL_Ptag{
    font-size: 10px;
    color: #909090;
    font-weight: 500;
    margin: 0;
}
.title_Vertical{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
}
.colr_Black{
    color: #000;
}
.colr_Green{
    color: #0F883F;
}
.colr_Red{
    font-size: 12px;
    color: #F00;
    font-weight: 500;
}
.colr_yellow{
    font-size: 12px;
    color: #F90;
    font-weight: 500;
}
.endDate_Vertical{
    font-size: 12px;
    color: #606060;
    font-weight: 400;
}
tr:nth-child(2) {
    border-bottom: 1px solid #D9D9D9;
}
tr:nth-child(3) {
    border-bottom: 1px solid #D9D9D9;
}
tr:nth-child(4) {
    border-bottom: 1px solid #D9D9D9;
}
tr:nth-child(5) {
    border-bottom: 1px solid #D9D9D9;
}
tr:nth-child(6) {
    border-bottom: 1px solid #D9D9D9;
}
tr:nth-child(7) {
    border-bottom: 1px solid #D9D9D9;
}
tr:nth-child(8) {
    border-bottom: 1px solid #D9D9D9;
}
tr:nth-child(9) {
    border-bottom: 1px solid #D9D9D9;
}
.tr_NewTest{
    border-bottom: 1px solid #D9D9D9;
}
.tr_NewTest:hover{
    cursor: pointer;
    background-color: #E6ECF0;
}
td,th{
    padding: 8px 0;
}
.class_SubjectMainArea{
    width: 33.33%;
    padding: 8px 1%;
}
.class_SubjectCont{
    padding: 8px;
    border-radius: 8px;
    border: 2px solid #EAEAEA;
    background: #FFF;
    gap: 8px;
}
.class_SubjectDiv{
    display: flex;
    justify-content: space-between;
}
.image_TextSec{
    display: flex;
    gap: 8px;
}
.SubjectContImg{
    width: 36px;
    height: 36px;
    border-radius: 48px;
}
.titleForHeding{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    padding-bottom: 8px;
}
.Subject{
    padding: 4px 8px;
    border-radius: 40px;
    background: #D8E3FF;
    font-size: 11px;
    color: #000;
    font-weight: 500;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}
.by_Quizzer{
    font-size: 11px;
    color: #909090;
    font-weight: 500;
    margin: 0;
}
.ClassTex{
    font-size: 14px;
    color: #FF4C00;
    font-weight: 600;
    margin: 0;
    padding-bottom: 8px;
}
.Section{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    text-align: right;
}
.CreateA_QuizMainArea{
    display: flex;
    padding: 24px 72px;
    align-items: center;
    gap: 170px;
    border-radius: 8px;
    background: #FFF;
}
.CreateA_QuizTextSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
.CreateA_QuizTitel{
    font-size: 22px;
    color: #000;
    font-weight: 600;
    margin: 0;
}
.CreateA_QuizButton{
    display: flex;
    align-items: flex-start;
    gap: 24px;
}
.CreateA_QuizLoginBU{
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 6px 32px;
    border-radius: 4px;
    border: none;
    background: #FF7A00;
    margin: 0 auto;
    cursor: pointer;
    overflow: hidden;
}
.CreateA_QuizImgSec img{
    width: 231px;
    height: 157px;
    flex-shrink: 0;
}
.Topic_SpecificMainArea{
    display: flex;
    width: 100%;
    padding: 16px 24px;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    border: 2px solid #EAEAEA;
    background: #FFF;
}
.Topic_SpecificHeading_ButtomSec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.Topic_SpecificTitelSmall{
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin: 0;
}
.Topic_SpecificTitelBig{
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin: 0;
}
.Topic_SpecificLoginBU{
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 6px 32px;
    border-radius: 4px;
    border: none;
    background: #FF7A00;
    cursor: pointer;
    overflow: hidden;
}
.Topic_SpecificCard{
    width: 20%;
    padding: 8px 1%;
}
.Topic_Specific_ClassList{
    display: flex;
    padding: 24px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: #FFE7B8;
}
.Topic_Specific_ClassNub{
    font-size: 16px;
    color: #000;
    font-weight: 700;
    margin: 0;
}
.Topic_Specific_ClassTex{
    font-size: 12px;
    color: #413F3D;
    font-weight: 500;
    margin: 0;
}
.greenColr{
    background: #DBFFB8;
}
.blueColr{
    background: #B8EEFF;
}
.purpleColr{
    background: #D7B8FF;
}
.Explore_EndingHalf{
    width: 50%;
}
.Explore_EndingCusRow{
    display: flex;
    flex-wrap: wrap;
}
.Explore_EndingFeild{
    width: 46%;
    padding: 15px 2%;
}
.Explore_EndingMainCont{
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 8px;
    border: 2px solid #00B2FF;
    background: #B8EEFF;
    flex-direction: column;
}
.classText{
    font-size: 12px;
    color: #006DD2;
    font-weight: 700;
    margin: 0;
    padding-bottom: 9px;
}
.subjectText{
    font-size: 13px;
    color: #000;
    font-weight: 600;
    margin: 0;
    padding-bottom: 9px;
}
.subjectMiniText{
    font-size: 12px;
    color: #413F3D;
    font-weight: 500;
    margin: 0;
}
.Explore_EndingButton{
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #000;
    font-size: 12px;
    color: #FFF;
    font-weight: 700;
    border: none;
    width: 90px;
    margin-top: 20px;
}
.yelloColr__Pro{
    background: #FFE7B8;
    border: 2px solid #FF5C00;
}  
.purpleColr__Pro{
    border: 2px solid #D200BD;
    background: #F8CDFF;
}
.greenColr__Pro{
    border: 2px solid #00D23B;
    background: #D4FFCD;
}
.colrOreng__Txt{
    color: #FF5C00;
}
.colrpurple__Txt{
    color: #D200BD;
}
.colrgreen__Txt{
    color: #00D23B;
}
.Explore_EndingHalfMainCont{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    padding: 20px 8px;
}
.Explore_EndingHalfMainContText{
    font-size: 32px;
    color: #000;
    font-weight: 400;
    text-align: right;
}
.Explore_EndingHalfMainCont img{
    width: 290px;
}
.noData_FoundSec{
    display: flex;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgba(0,0,0,.16);
    height: 450px;
    padding: 30px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}
.noData_Found_SecUnder{
    align-content: center;
    opacity: .4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.noData_Found_SecUnder img{
    margin-bottom: 40px;
    width: 120px;
}
.noData_Found_SecUnder p{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}

.studentDashboard_Row{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.studentDashboard_BigDiv_Sec{
    width: 70%;
    padding: 8px;
}
.studentDashboard_SmallDiv_Sec{
    width: 30%;
    padding: 8px;
    margin-top: 10px;
}
.studentDashboard_SmallDiv_Area{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #fff;
}
.smallDiv_secTitel{
    font-size: 16px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.Latest_NewsHalfGroup{
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: #FFF;
}
.img_Div{
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 40px;
    background: #FFE1D8;
}
.LeagueRankHalfGroupNumText{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.LeagueRankHalfGroupNamText{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.Upcoming_TestsHalfGroup{
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: #EAEAEA;
}