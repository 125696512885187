.Container {
    width: 1024px;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.create_AccomplishmentSec{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    margin-top: 16px;
    margin-bottom: 8px;
}
.create_AccomplishmentSec p{
    font-size: 18px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.FormBoxArea{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}
.FormField{
    padding: 8px 0;
    width: 100%;
}
.FormLabel{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    padding-bottom: 8px;
    padding-left: 4px;
}
.FormInput{
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    width: 100%;
    padding: 16px;
    font-weight: 400;
    color: #000;
    font-size: 14px;
    outline: none;
    height: 40px;
}
.FormRow{
    display: flex;
    margin: 0 -8px;
}
.FormRowHalf{
    width: 50%;
    padding: 0 8px;
}
.ButtonSec{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid #D9D9D9;
}
.SaveBU{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    margin-top: 16px;
}
.login_ErrorM{
    color: #f44336;
    font-size: 13px;
    margin: 0;
    margin-top: 5px;
    padding-left: 6px;
}