.Container {
    width: 1024px;
    margin: 0 auto;
}

.Explor_TestsMainArea{
    margin-top: 8px;
    background: #FF7A00;
    padding: 48px 24px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 944px;

}

.Explor_TestsTextSec{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Explor_TestsImgSec img{
    width: 242px;
    height: 213px;
    flex-shrink: 0
}

.Explor_TestsTitelBig{
    color: #FFF;
    text-align: center;
    /* font-family: Inter; */
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    width: 537px;
}

.Explor_TestsTitelBig1{
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin: 0;
    text-align: center;
    padding-bottom: 8px;
}

.Explor_TestsTitelSmall{
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    margin: 0;
    text-align: center;
    padding-bottom: 16px;
    width: 364px;
}

.Explor_TestsLoginBU{
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #000;
    padding: 8px 16px;
    width: 144px;
    border-radius: 8px;
    border: none;
    background: #FFF;
    margin: 0 auto;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
}

.CreateA_QuizMainArea{
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 80px;
    background: #FFF;
    margin: 16px 0 24px;
    width: 944px;
}

.CreateA_QuizTextSec{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
}

.CreateA_QuizTitel{
    font-size: 32px;
    color: #000;
    font-weight: 400;
    margin: 0;
}

.CreateA_QuizButton{
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.CreateA_QuizLoginBU{
    font-size: 16px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 16px;
    border-radius: 4px;
    border: none;
    background: #FF7A00;
    margin: 0 auto;
    cursor: pointer;
    overflow: hidden;
}

.CreateA_QuizMainArea1{
    display: inline-flex;
    align-items: center;
    gap: 16px;
}

.CreateA_QuizImgSec img{
    width: 239px;
    height: 210px;
    object-fit: cover;
}
.CreateA_QuizTextSec1{
    display: flex;
    width: 600px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.CreateA_QuizTitel1{
    align-self: stretch;
    color: #000;
    text-align: right;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.CreateA_QuizTextSec2{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
}