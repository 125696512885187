.FooterMain {
    width: 85px!important;
    overflow: hidden;
    z-index: 99999;
    left: 0;
    right: auto;
    top: 0;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    position: fixed;
    flex-direction: column;
    background-color: #006;
    transition: all .3s ease-in-out;
}

.FooterMain:hover {
    width: 258px!important;
}

.SideAvatarMain {
    padding: 10px 0 0!important;
}

.SideAvatarMainUnder {
    padding: 10px 17px 10px 20px!important;
    transition: all .4s ease-in-out;
    box-sizing: border-box!important;
    width: 100%!important;
    display: flex;
}

.SideAvatarSec {
    padding: 4px;
}

.SideAvatarSecUnder {
    margin-right: 4px;
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;
}

.SideAvatar {
    background-color: #0082ff;
    color: #F7F9FC;
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.1607142857142858rem;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
}

.UserActiveState {
    border: 2px solid rgb(255, 255, 255);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #44ac09;
    color: #fff;
    right: 14%;
    bottom: 14%;
    transform: scale(1) translate(50%, 50%);
    transform-origin: 100% 100%;
    display: flex;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
}

.SideUserName {
    display: flex;
    align-items: center;
    white-space: nowrap;
    visibility: hidden;
    padding: 4px 4px 4px 10px;
    transition: all .3s ease-in-out;
}

.FooterMain:hover .SideUserName {
    visibility: visible;
}

.SideUserName span {
    font-size: 14px;
    font-weight: 500;
    color: #eee;
}

.SideMenuLinkSec {
    position: relative;
    height: 100%;
    overflow: auto;
    touch-action: auto;
    width: 258px;
}

.SideMenuLinkSecUnder {
    padding: 10px 0;
}

.SideMenuLinkSecUnder ul {
    padding: 0;
    margin: 0;
}

.SideMenuLinkSecUnder ul li {
    list-style: none;
}

.SideMenuLinkSecUnder ul li a {
    padding: 14px 28px 14px 32px;
    font-weight: 400;
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.SideMenuLinkSecUnder ul li a:hover {
    background-color: #030395;
}

.SideMenuLinkSecUnder ul li a svg {
    min-width: 20px;
    min-height: 20px;
    color: rgb(238, 238, 238);
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.8;
    stroke-width: 2;
}

.SideMenuLinkSecUnder ul li a svg.DownIndi {
    min-width: 20px;
    min-height: 20px;
    color: rgb(238, 238, 238);
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.6;
    stroke-width: 2;
    margin-left: auto;
    transform: rotate(0deg);
    /* transition: all .4s ease-in-out; */
}

.SideMenuLinkSecUnder ul li.DropDownSec.Open a svg.DownIndi {
    transform: rotate(-180deg);
}

.SideLinkText {
    color: rgb(238, 238, 238);
    font-size: 13px;
    padding: 0px 16px;
    display: block;
    font-weight: 500;
    margin: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
}

.FooterMain:hover .SideLinkText {
    visibility: visible;
}

.DropDownList {
    padding: 0;
    margin: 0;
    min-height: 0px;
    max-height: 0px;
    overflow: hidden;
    transition: all .5s ease-in-out;
}

.DropDownSec.Open .DropDownList {
    min-height: 39px;
    max-height: 500px;
}

.DropDownList li {
    list-style: none;
}

.DropDownList li a {
    font-size: 13px;
    font-weight: 500 !important;
    color: #fff;
    opacity: 0.7;
    padding: 10px 12px 10px 69px !important;
    transition: all .3s ease-in-out;
    visibility: hidden;
}

.FooterMain:hover .DropDownList li a {
    visibility: visible;
}

.DropDownList li a:hover {
    background-color: #030395;
}