.Container {
    width: 1024px;
    margin: 0 auto;
}
.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.Topic_SpecificMainArea{
    display: flex;
    width: 100%;
    padding: 16px 24px;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    border: 2px solid #EAEAEA;
    background: #FFF;
}

.Topic_SpecificHeading_ButtomSec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.Topic_SpecificTitelSmall{
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin: 0;
}

.Topic_SpecificTitelBig{
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin: 0;
}

.Topic_SpecificLoginBU{
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 6px 32px;
    border-radius: 4px;
    border: none;
    background: #FF7A00;
    cursor: pointer;
    overflow: hidden;
}

.Topic_SpecificCard{
    width: 25%;
    padding: 8px 1%;
}

.Topic_Specific_ClassList{
    display: flex;
    padding: 24px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: #FFE7B8;
    cursor: pointer;
}

.Topic_Specific_ClassNub{
    font-size: 16px;
    color: #000;
    font-weight: 700;
    margin: 0;
}

.Topic_Specific_ClassTex{
    font-size: 12px;
    color: #413F3D;
    font-weight: 500;
    margin: 0;
}

.greenColr{
    background: #DBFFB8;
}

.blueColr{
    background: #B8EEFF;
}

.purpleColr{
    background: #D7B8FF;
}

.yelloColr{
    background: #FFE7B8;
}