.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.Container {
    width: 1140px;
    margin: 0 auto;
}
.MainDivArea{
    margin-top: 16px;
}
.My_VaultSec{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    margin-bottom: 8px;
}
.My_VaultSec p{
    font-size: 18px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.My_testsSec{
    display: flex;
    padding: 8px 24px 8px 16px;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #FFF;
    margin-bottom: 2px;
}
.My_testsGroup{
    display: flex;
    align-items: center;
    gap: 16px;
}
.My_testsBigTitel{
    font-size: 17px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.My_testsGroup1{
    display: flex;
    align-items: center;
    gap: 24px;
}
.flex {
    display: flex;
    gap: 4px;
}
.My_testsTitleSMl{
    font-size: 14px;
    color: #606060;
    font-weight: 500;
    margin: 0;
}
.tableSec{
    border-radius: 8px;
    background: #FFF;
    padding: 16px 16px 21px 16px;
}
.tabelCls{
    width: 100%;
}
.tabelCls td, .tabelCls th {
    padding: 8px;
}
.tabelCls tr {
    border-bottom: 1px solid #D9D9D9;
}
.th_HedingCustom{
    font-size: 14px;
    color: #2D2D2D;
    font-weight: 500;
}
.align_Center {
    text-align: center;
}
.classAroDiv{
    display: flex;
    align-items: center;
    gap: 8px;
}
.classAroDivText{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.relative_Position{
    position: relative;
}
.td_Titel{
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
.td_TitelColr_pink{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
}
.td_TitelColr_yellow{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
}
.td_TitelColr_green{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
}
.tableBU{
    display: inline-block;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    margin-top: 24px;
}
.td_TitelColr_pink::after{
    background: #FF00A8;
    bottom: 0;
    content: "";
    height: 4px;
    left: -12px;
    position: absolute;
    transform: translate(-50%);
    width: 30px;
    transform: rotate(-90deg);
    top: 18px;
    border-radius: 2px;
}
.td_TitelColr_yellow::after{
    background: #FFC700;
    bottom: 0;
    content: "";
    height: 4px;
    left: -12px;
    position: absolute;
    transform: translate(-50%);
    width: 30px;
    transform: rotate(-90deg);
    top: 18px;
    border-radius: 2px;
}
.td_TitelColr_green::after{
    background: #00DED1;
    bottom: 0;
    content: "";
    height: 4px;
    left: -12px;
    position: absolute;
    transform: translate(-50%);
    width: 30px;
    transform: rotate(-90deg);
    top: 18px;
    border-radius: 2px;
}
.noData_FoundSec{
    display: flex;
    background: #fff;
    height: 300px;
    position: relative;
}
.noData_Found_SecUnder{
    align-content: center;
    opacity: .4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.noData_Found_SecUnder img{
    margin-bottom: 40px;
    width: 120px;
}
.noData_Found_SecUnder p{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}