.Container {
    width: 1024px;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.my_DashboardMainArea{
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
    margin-top: 16px;
}
.my_DashboardNam{
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.my_DashboarHeading{
    font-size: 25px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.my_DashboarSubHeading{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.text_ButtonGrop{
    width: 100%;
    display: flex;
    align-items: center;
}
.UnderSmLContGroup {
    display: flex;
    align-items: center;
    gap: 16px;
}
.UnderSmLbigTitle {
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.UnderSmLContGroup1 {
    display: flex;
    align-items: center;
    gap: 24px;
}
.flex {
    display: flex;
    gap: 4px;
}
.UnderSmLTitleSMl {
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    margin: 0;
}
.UnderSmLContGroup2{
    display: flex;
    align-items: center;
    gap: 8px;
}
.My_TestsBU{
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 0px 8px 8px 0px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
}
.My_TestsBU svg{
    width: 12px;
    height: 12px;
    color: #FFF;
}
.BigDiv_Sec_UnderSmL {
    display: flex;
    padding: 8px 24px 8px 16px;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 8px 0px 0px 8px;
    background: #FFF;
    justify-content: space-between;
}
.BigDiv_Sec{
    width: 100%;
    padding: 8px;
}
.table_Sec{
    border-radius: 8px;
    background: #FFF;
    padding: 10px 12px;
    margin-top: 8px;
    margin-bottom: 14px;
}
.th_HedingCustom{
    font-size: 14px;
    color: #4B4B4B;
    font-weight: 600;
}
.align_Center {
    text-align: center;
}
.align_Right {
    text-align: right;
}
.title_VerticalFest_TD{
    font-size: 14px;
    color: #191D63;
    font-weight: 500;
}
.smaLL_Ptag {
    font-size: 10px;
    color: #909090;
    font-weight: 500;
    margin: 0;
}
.title_Vertical {
    font-size: 12px;
    color: #606060;
    font-weight: 400;
}
.colr_Red {
    font-size: 14px;
    color: #FF0000;
    font-weight: 500;
}
.colr_Green {
    font-size: 14px;
    color: #0F883F;
    font-weight: 500;
}
.colr_yellow {
    font-size: 14px;
    color: #F90;
    font-weight: 500;
}
.table{
    width: 100%;
}
.table td, .table th {
    padding: 8px;
}
.table tr {
    cursor: pointer;
}
.table tr:nth-child(1) {
    border-bottom: none;
}
.table tr:not(:nth-child(1)) {
    border-bottom: 1px solid #D9D9D9;
}
.my_TestsDiv{
    width: 33.33%;
    padding: 0 4px;
    margin-top: 8px;
}
.my_TestsUnder{
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 2px solid #EAEAEA;
    background: #FFF;
}
.my_TestsUnder img{
    height: 180px;
    width: 100%;
}
.Subject {
    padding: 4px 8px;
    border-radius: 40px;
    background: #D8E3FF;
    font-size: 11px;
    color: #000;
    font-weight: 500;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}
.my_TestsAlgebraTex{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    padding-bottom: 24px;
}
.my_TestsByQuizzerTex{
    font-size: 11px;
    color: #909090;
    font-weight: 500;
    margin: 0;
}
.SmallDiv_Sec{
    width: 30%;
    padding: 8px;
}
.smallDIvSec{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}
.smallDIvSecTitel{
    font-size: 16px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.Latest_NewskHalfGroup{
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: #FFF;
}
.imgDiv{
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 40px;
    background: #D8EEFF;
}
.pink_Colr{
    background: #FFE1D8;
}
.LeagueRankHalfGroupNubText {
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.LeagueRankHalfGroupNamText{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.smallDIvSecGroup{
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 2px solid #0F883F;
    background: #E2FFEA;
}
.smallDIvSecGroupBigText{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.smallDIvSecGroupSmallText{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.smallDIvSec2{
    display: flex;
    padding: 26px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
    margin-top: 8px;
}
.noData_FoundSec{
    display: flex;
    background: #fff;
    height: 300px;
    position: relative;
}
.noData_Found_SecUnder{
    align-content: center;
    opacity: .4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.noData_Found_SecUnder img{
    margin-bottom: 40px;
    width: 120px;
}
.noData_Found_SecUnder p{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}
.paginationDiv{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
}
.list_FilterSec{
    padding: 10px 20px;
    width: 220px;
}
.list_FilterCard{
    padding: 10px 0;
    width: 100%;
}
.list_FilterBuArea{
    display: flex;
    justify-content: space-between;
}
.list_FilterBuArea button{
    background: rgba(23,79,185,.2);
    border: none;
    border-radius: 4px;
    color: #174FB9;
    display: block;
    font-size: 12px;
    font-weight: 500;
    padding: 6px 8px;
    text-align: center;
    text-transform: uppercase;
    width: 46%;
}
.SortAscIcon{
    width: 20px;
}
.SortDescIcon{
    width: 20px;
}
.editCus{
    color: rgba(0, 0, 0, 0.87);
}