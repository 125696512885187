.FooterMain {
    background-color: bisque;
}

.Error404 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 200px 0;
}

.Error404 img {
    width: 100%;
    max-width: 450px;
}