.Container {
    width: 1024px;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.MainDiv_Area{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
    margin-top: 16px;
    min-height: 542px;
}
.general_TextAroSecTitel {
    font-size: 16px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.accomplishment_FormField {
    padding: 8px 0;
    width: 100%;
}
.accomplishment_FormField_30{
    padding: 8px 0;
    width: 30%;
}
.accomplishment_Label {
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    padding-bottom: 8px;
    padding-left: 2px;
}
.accomplishment_inputTy {
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    width: 100%;
    padding: 16px;
    font-weight: 400;
    color: #000;
    font-size: 14px;
    outline: none;
    height: 40px;
}
.accomplishment_inputTy2 {
    border-radius: 8px;
    background: #FFF;
    width: 100%;
    font-weight: 400;
    color: #000;
    font-size: 14px;
    outline: none;
    height: 40px;
}
.chackBox_BUSec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    margin-top: 8px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 16px;
}
.Tex_InputChackGrop {
    display: flex;
    gap: 8px;
}
.chackBox_Text {
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    padding-right: 8px;
}
.chackBox_labelTex {
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.chackBoxButton {
    display: flex;
    width: 192px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #174FB9;
    border: none;
    font-size: 12px;
    color: #FFF;
    font-weight: 600;
    cursor: pointer;
}
.ending_SaveSec {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.ending_SaveBU {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    margin-bottom: 8px;
}
.login_ErrorM{
    color: #f44336;
    font-size: 13px;
    margin: 0;
    margin-top: 5px;
    padding-left: 6px;
}