.Container {
    width: 1024px;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.Row{
    display: flex;
}
.signup_MainDiv{
    width: 100%;

}
.Header{
    background: #FF4C00;;
    padding: 0px 51px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    /* display: block; */
    font-weight: 600;
    text-decoration: none;
    color:  #fff;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.main_DivSec{
    padding-top: 16px;
}
.mainDIvBig_Sec{
    width: 70%;
    padding: 8px;
}
.mainDivSec_Smalldiv{
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
}
.classHader{
    font-size: 14px;
    color: #FF4C00;
    font-weight: 600;
    margin: 0;
    padding-bottom: 6px;
}
.titleForHeding{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    padding-bottom: 8px;
}
.Subject{
    padding: 4px 8px;
    border-radius: 40px;
    background: #FFE1D8;
    font-size: 11px;
    color: #000;
    font-weight: 500;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}
.by_Quizzer{
    font-size: 11px;
    color: #909090;
    font-weight: 500;
    margin: 0;
}
.share_Examlink{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    width: 245px;
    margin-bottom: 8px;
}.copy_Examlink{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: #D0F7FF;
    font-size: 14px;
    color: #174FB9;
    font-weight: 600;
    border: none;
    width: 245px;
}
.copy_Examlink svg{
    width: 13.996px;
    height: 14px;
    color: #174FB9;
    margin-right: 8px;
}
.mainDivSec_Bigdiv{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    background: #FFF;
    border-radius: 8px;
    margin-top: 8px;
}
.details_Area{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.details_AreaHeding{
    font-size: 17px;
    color: #000;
    font-weight: 500;
    margin: 0;
}
.details_AreaClassSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}
.ClassSecHeading{
    font-size: 14px;
    color: #646464;
    font-weight: 600;
    margin: 0;
}
.ClassSecHeading span{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.ClassSecGroupArea{
    display: flex;
    align-items: center;
    gap: 8px;
}
.massgLogo{
    border-radius: 8px;
    background: #D0F7FF;
    display: flex;
    padding: 10px;
    align-items: flex-start;
}
.incosMS{
    border-radius: 8px;
    background: #FFE1D8;
    display: flex;
    padding: 10px;
    align-items: flex-start;
}
.incosMS svg{
    color: #F00;
    width: 18px;
    height: 18px;
}
.incosCK{
    border-radius: 8px;
    background: #D0F7FF;
    display: flex;
    padding: 10px;
    align-items: flex-start;
}
.incosCK svg{
    color: #fff;
    width: 18px;
    height: 18px;
}
.GroupAreaSmallTitel{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.GroupAreaBigTitel{
    font-size: 15px;
    color: #060710;
    font-weight: 700;
    margin: 0;
}
.moreDetailsSec{
    display: flex;
    height: 56px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.moreDetailsHalf{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 48px;
}
.moreDetailsText{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.moreDetailsMonth{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    margin: 0;
}
.moreDetailsTime{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    margin: 0;
}
.EndBU{
    display: flex;
    width: 200px;
    padding: 13px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #174FB9;
    font-size: 12px;
    color: #FFF;
    font-weight: 600;
    border: none;
    margin-top: 16px;
}
.table{
    width: 100%;
}
.table td,.table th{
    padding: 8px 48px 8px 0px;
}
.mainDIvSmall_Sec{
    width: 30%;
    margin-top: 16px;
    padding: 8px;
}
.total_QuestionsSec{
    display: flex;
    /* width: 304px; */
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}
.smallDIvSec2_CostUL {
    margin: 0;
    padding: 0;
    width: 100%;
}
.smallDIvSec2_CostUL li {
    display: flex;
    list-style: none;
    padding: 6px 0;
    justify-content: space-between;
    align-items: center;
}
.flex_li{
    display: flex;
    align-items: center;
    gap: 8px;
}
.group_Icon {
    display: flex;
    /* width: 38px;
    height: 38px; */
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: #FFE1D8;
}
.background_Aqua{
    background: #FEFFD8;
}
.background_Green{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: #D8FFDC;
}
.background_Green svg{
    width: 15px;
    height: 15px;
    color: #25C147;
}
.group_Icon svg {
    width: 15px;
    height: 15px;
    color: #F00;
}
.smallDIvSec2_CostTitle{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.smallDIvSec2_CostTitleNum{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.boardSec{
    width: 303px;
    height: 1px;
    background: #D9D9D9;
    margin-top: 4px;
}
.Login_DetailsSec{
    /* display: flex; */
    /* width: 304px; */
    padding: 16px;
    /* flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px; */
    border-radius: 8px;
    background: #FFF;
    margin-top: 8px;
}  
.Login_DetailsHeading{
    font-size: 16px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}  
.accomplishment_FormField {
    padding: 8px 0;
    width: 100%;
    display: grid;

}
.accomplishment_Label {
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    padding-bottom: 8px;
    padding-left: 2px;
}  
.number_TextTy {
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    border: none;
}
.accomplishment_inputPW {
    font-size: 14px;
    color: #909090;
    font-weight: 500;
    border: none;
}
.Button_CusRow{
    display: flex;
    gap: 8px;
    margin-top: 20px;
}
.blueColer_BU{
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #174FB9;
    font-size: 12px;
    color: #FFF;
    font-weight: 600;
    border: none;
}
.aqueColer_BU{
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #D0F7FF;
    font-size: 12px;
    color: #174FB9;
    font-weight: 600;
    border: none;
}