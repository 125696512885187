body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
body {
  font-family: 'Montserrat', sans-serif !important;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
      url("./fonts/proxima-nova.otf");
  font-weight: 100;
}

@font-face {
  font-family: "ProximaThin";
  src: local("ProximaThin"),
      url("./fonts/proxima-nova-thin.otf");
  font-weight: 100;
}

@font-face {
  font-family: "ProximaLight";
  src: local("ProximaLight"),
      url("./fonts/proxima-nova-light.otf");
  font-weight: 200;
}

@font-face {
  font-family: "ProximaRegular";
  src: local("ProximaRegular"),
      url("./fonts/proxima-nova-regular.otf");
  font-weight: 300;
}

@font-face {
  font-family: "ProximaMedium";
  src: local("ProximaMedium"),
      url("./fonts/proxima-nova-medium.otf");
  font-weight: 400;
}

@font-face {
  font-family: "ProximaSemibold";
  src: local("ProximaSemibold"),
      url("./fonts/proxima-nova-semibold.otf");
  font-weight: 500;
}

@font-face {
  font-family: "ProximaBold";
  src: local("ProximaBold"),
      url("./fonts/proxima-nova-bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "ProximaExtrabold";
  src: local("ProximaExtrabold"),
      url("./fonts/proxima-nova-extrabold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "ProximaBlack";
  src: local("ProximaBlack"),
      url("./fonts/proxima-nova-black.otf");
  font-weight: 800;
}

form {
  width: 100%;
}

.CustomerReview .react-multiple-carousel__arrow {
  bottom: 0;
  bottom: 0px;
  background: transparent;
  border-radius: 5px;
  min-width: 30px;
  min-height: 30px;
  transition: all .3s;
}

.CustomerReview .react-multiple-carousel__arrow:hover {
  background: rgb(0 0 102 / 80%) !important;
}

.CustomerReview .react-multiple-carousel__arrow::before {
  font-size: 18px;
  color: #333;
}

.CustomerReview .react-multiple-carousel__arrow:hover::before {
  color: #fff;
}

.CustomerReview .react-multiple-carousel__arrow--left {
  left: calc(47% + 1px);
}

.CustomerReview .react-multiple-carousel__arrow--right {
  right: calc(47% + 1px);
}

.CustomerReview .react-multi-carousel-list {
  position: unset;
}

.TrendingSec {
  padding-top: 250px;
  margin-top: -250px;
}

.ReservationStepper .MuiStep-root {
  padding: 0;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer {
  padding: 0;
  margin-bottom: 15px;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg {
  width: 60px;
  height: 60px;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg.Mui-active,
.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg.Mui-completed {
  color: #008000;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4A4A4A;
}

.ReservationStepper .MuiStepConnector-root {
  padding-top: 30px;
}

.ReservationStepper .MuiStepConnector-root .MuiStepConnector-line {
  border-top-width: 4px;
}

.ReservationStepper .MuiStepConnector-root.Mui-active .MuiStepConnector-line,
.ReservationStepper .MuiStepConnector-root.Mui-completed .MuiStepConnector-line {
  border-color: #008000;
  border-top-width: 3px;
}

.ReservationStepper {
  padding: 90px 0 !important;
  align-items: flex-start !important;
}

.StepperBuSec {
  width: 100%;
  padding: 20px 0 !important;
  display: flex;
  justify-content: space-between;
  position: sticky;
    bottom: 0;
    background-color: rgba(255,255,255,0.7);
    z-index: 2;
    margin: 0 -5px;
    width: Calc(100% + 10px);
}

.ReservationSec .container > div {
  margin-bottom: 30px;
}

.TrendingBUBrown {
  width: auto !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 30px !important;
  background: #7C4836 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 6px !important;
  margin-left: 0px !important;
  position: relative !important;
  overflow: hidden !important;
  transition: all .3s ease-in-out !important;
}

.TrendingBUBrown:hover{
  background: #532d20 !important;
}

.TrendingBUBrown.Mui-disabled,
.TrendingBUBrown.Mui-disabled:hover {
  color: rgba(0, 0, 0, 0.26) !important;
  background: #e5e5e5 !important;
}

.TrendingBUGreen {
  width: auto !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 30px !important;
  background: #008000 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 6px !important;
  margin-left: 0px !important;
  position: relative !important;
  overflow: hidden !important;
  transition: all .3s ease-in-out !important;
}

.TrendingBUGreen:hover{
  background: #015801 !important;
}

.TrendingBUGreen.Mui-disabled,
.TrendingBUGreen.Mui-disabled:hover {
  color: rgba(0, 0, 0, 0.26) !important;
  background: #e5e5e5 !important;
}

.AddOnAccording .MuiPaper-root.Mui-expanded {
  margin: 0 !important;
}

.AddOnAccording .MuiPaper-root {
  box-shadow: none !important;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
}

/* .AddOnAccording .MuiPaper-root:last-child {
  border: none;
} */

.AddOnAccording .MuiPaper-root::before {
  display: none;
}

.AddOnAccording .MuiPaper-root.Mui-expanded .MuiButtonBase-root.Mui-expanded {
  min-height: 48px !important;
}

.AddOnAccording .MuiPaper-root .MuiButtonBase-root {
  padding: 15px 0 !important;
}

.AddOnAccording .MuiPaper-root.Mui-expanded .MuiButtonBase-root.Mui-expanded .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

.AddOnAccording {
  margin: 25px 0;
}

.AddOnAccording .MuiPaper-root .MuiCollapse-root {
  border-top: 1px solid #f3f3f3;
}

.AddOnAccording .MuiPaper-root .MuiCollapse-root .MuiAccordionDetails-root {
  padding: 25px 15px !important;
}

.MuiDialog-container > div {
  overflow: unset;
}

.CustomerDash .MuiBox-root {
  border: none;
}

.CustomerDash button.MuiButtonBase-root {
  padding: 12px 0px;
    max-width: 360px;
    min-width: 50px;
    position: relative;
    min-height: 48px;
    margin-right: 35px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.CustomerDash button.MuiButtonBase-root span {
  display: none;
}

.CustomerDashDetails {
  padding: 40px 0;
}

.CustomerDashDetails .MuiBox-root {
  padding: 0;
}

.ProductSlider .react-multi-carousel-list {
  position: relative;
  padding-bottom: 100px;
}

.ProductSlider .react-multiple-carousel__arrow {
  bottom: 0px;
  background: transparent;
  border-radius: 5px;
  min-width: 30px;
  min-height: 30px;
  transition: all .3s;
}

.ProductSlider .react-multiple-carousel__arrow:hover {
  bottom: 0;
  bottom: 0px;
  background: rgb(0 0 102 / 80%);
  border-radius: 5px;
  min-width: 30px;
  min-height: 30px;
}

.ProductSlider .react-multiple-carousel__arrow::before {
  font-size: 18px;
  color: #333;
}

.ProductSlider .react-multiple-carousel__arrow:hover::before {
  font-size: 18px;
  color: #fff;
}

.ProductSlider .react-multiple-carousel__arrow--left {
  left: calc(47% + 1px);
}

.ProductSlider .react-multiple-carousel__arrow--right {
  right: calc(47% + 1px);
}

.FAQArea .MuiPaper-root {
  box-shadow: none;
  border-bottom: 1px solid #cdcdcd;
}

.FAQArea .MuiPaper-root:last-of-type {
  border: none;
}

.FAQArea .MuiPaper-root::before {
  display: none;
}

.FAQArea .MuiButtonBase-root {
  padding: 0;
  min-height: 64px;
}

.FAQArea .MuiAccordionDetails-root {
  padding: 16px 16px;
  background: #f3f7f3;
}

.FAQArea .MuiPaper-root.Mui-expanded {
  margin: 0px 0;
}

.CustomerDash .MuiTabs-scroller {
  overflow: auto !important;
}

.UserName {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/************************** Admin CSS ************************************/
.DashboardMainBody {
  width: 100%;
  position: relative;
  padding-left: 85px;
}

.TableContent header {
  padding: 0;
}

.TableFilterHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DataTableSearch {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #bcbcbc;
}

.DataTableSearch .MuiFormControl-root {
  width: 100%;
}

.DataTableSearch .MuiFormControl-root input {
  width: 100%;
  height: auto;
  padding: 5px 15px 5px 0;
  font-size: 14px;
}

.DataTableSearch .MuiOutlinedInput-notchedOutline {
  display: none;
}

.DataTableSearch .CloseBU,
.DataTableSearch .CloseBU:hover {
  padding: 0;
  min-width: 40px;
  background: none;
  display: flex;
  justify-content: flex-end;
  color: #333;
}

.DataTableSearch .CloseBU svg {
  width: 18px;
  height: 18px;
}

.TableFilterBUSec {
  width: auto;
  display: flex;
  margin-left: 30px;
}

.TableAddBU {
  align-items: center;
  padding: 9px 14px;
  background: #40bf0c;
  display: flex;
  min-height: 0;
  color: #fff !important;
  border-radius: 5px;
  transition: all .3s;
  outline: none;
  height: 38px;
  cursor: pointer;
}

.TableAddBU:hover {
  background: #329909;
}

.TableAddBU svg {
  width: 20px;
  height: 20px;
}

.DataTable .rdt_Table .rdt_TableHead .rdt_TableCol div,
.DataTable .rdt_Table .rdt_TableHead .rdt_TableCol div:hover,
.DataTable .rdt_Table .rdt_TableHead .rdt_TableCol div:focus {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  opacity: 1;
}

.DataTable .rdt_Table .rdt_TableHead .rdt_TableCol div span {
  margin-left: 15px;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow > div {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 58px;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background-color: rgba(0,0,255,4%);
  transition: all .3s ease-in-out;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow:nth-child(even) {
  background-color: rgba(255,255,255,100%);
  transition: all .3s ease-in-out;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow  div {
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  opacity: 1;
  /* transition: all .3s ease-in-out; */
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow:hover div {
  opacity: 1;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
  background-color: #eee;
}

.dropdown button {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.dropdown button:after {
  display: none;
}

.dropdown button svg {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.breadcrumb li a {
  text-decoration: none;
  color: #4a4a4a;
  opacity: .7;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s ease-in-out;
}

.breadcrumb li a:hover {
  text-decoration: underline;
  opacity: 1;
}

.breadcrumb li p {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
}

.LoaderCard {
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding: 1.5rem;
  margin: 1.5rem;
}

.Loader svg {
  width: 100%;
}

.TableLoader svg {
  height: 970px;
}

.FromLoader svg {
  height: 420px;
}

.CusModal .modal-header {
  padding: 0 20px;
  background-color: #000066;
  border-bottom: none;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  height: 60px;
}

.CusModal .modal-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  color: #fff;
}

.CusModal .modal-header .btn-close {
  border: none;
  color: #fff;
  padding: 0;
  filter: brightness(0) invert(1);
  opacity: 1;
}

.CusModal .modal-header .btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

.CusModal .modal-body {
  padding: 8px 24px;
}

.CusModal .modal-body p.Message {
  font-size: 15px;
  color: #858585;
  margin: 20px 0;
}

.PopUpCanBU {
  background: transparent !important;
  border: 1px solid #230058 !important;
  color: #230058 !important;
  transition: all .3s ease-in-out !important;
  min-width: 90px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  margin-right: 10px !important;
}

.PopUpCanBU:hover {
  background: #230058 !important;
  border: 1px solid #230058 !important;
  color: #fff !important;
}

.PopUpSubmitBU {
  background: #f44336 !important;
  border: 1px solid #f44336 !important;
  color: #fff !important;
  transition: all .3s ease-in-out !important;
  min-width: 90px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}

.PopUpSubmitBU:hover {
  background: #c13429 !important;
  border: 1px solid #c13429 !important;
  color: #fff !important;
}

.PopUpSubmitBU2 {
  background: #000066 !important;
  border: 1px solid #000066 !important;
  color: #fff !important;
  transition: all .3s ease-in-out !important;
  min-width: 90px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}

.PopUpSubmitBU2:hover {
  background: #000066 !important;
  border: 1px solid #000066 !important;
  color: #fff !important;
}

.modal-footer {
  border: none !important;
}

.MainLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: rgb(255 255 255 / 45%);
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999999999;
}

.MainLoader img {
  width: 80px;
}

.LoadingText {
  text-align: center;
  padding: 15px 0 0 0;
  font-size: 14px;
}

.e-upload-progress-wrap.hide {
  display: none!important;
}
/************************** Admin CSS ************************************/

@media (min-width: 360px) {
  .CustomerReview .react-multiple-carousel__arrow--left,
  .ProductSlider .react-multiple-carousel__arrow--left {
    left: calc(40% + 1px);
  }
  .CustomerReview .react-multiple-carousel__arrow--right,
  .ProductSlider .react-multiple-carousel__arrow--right {
    right: calc(40% + 1px);
  }  
  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg {
    width: 40px;
    height: 40px;
  }
  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
    font-size: 4px;
    opacity: 0;
  }
  .ReservationStepper .MuiStepConnector-root {
    padding-top: 20px;
  }
  .ReservationStepper {
    padding: 40px 0 20px 0 !important;
  }
}

@media (min-width: 480px) {}

@media (min-width: 576px) {
  .CustomerReview .react-multiple-carousel__arrow--left,
  .ProductSlider .react-multiple-carousel__arrow--left {
    left: calc(43% + 1px);
  }
  .CustomerReview .react-multiple-carousel__arrow--right,
  .ProductSlider .react-multiple-carousel__arrow--right {
    right: calc(43% + 1px);
  }
  .ReservationStepper {
    padding: 60px 0 20px 0 !important;
  }
}

@media (min-width: 768px) {
  .CustomerReview .react-multiple-carousel__arrow--left {
    left: calc(45% + 1px);
  }
  .CustomerReview .react-multiple-carousel__arrow--right,
  .ProductSlider .react-multiple-carousel__arrow--right {
    right: calc(45% + 1px);
  } 
  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
    font-size: 12px;
    opacity: 1;
  }
  .ReservationStepper {
    padding: 60px 0 60px 0 !important;
  }
}

@media (min-width: 992px) {
  .CustomerReview .react-multiple-carousel__arrow--left,
  .ProductSlider .react-multiple-carousel__arrow--left {
    left: calc(47% + 1px);
  }
  .CustomerReview .react-multiple-carousel__arrow--right,
  .ProductSlider .react-multiple-carousel__arrow--right {
    right: calc(47% + 1px);
  }
  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg {
    width: 60px;
    height: 60px;
  }  
  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
    font-size: 16px;
  }
  .ReservationStepper .MuiStepConnector-root {
    padding-top: 30px;
  }
  .ReservationStepper {
    padding: 90px 0 90px 0 !important;
  }
}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}

@media (min-width: 1600px) {}

@media (min-width: 1800px) {}


.DatePickerNew .react-datepicker-wrapper .react-datepicker__input-container{
  display: none !important;
}

.DatePickerNew .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{
  border-radius: 0.3rem;
  background-color: #F1F1F1;
  color: #ABABAB;
}

.DatePickerNew .react-datepicker__day--disabled:hover{
  background-color: #F1F1F1;
  color: #ABABAB;
}

.DatePickerNew  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  border-radius: 0.3rem;
  background-color: #008000;
  color: #fff; 
}

.CustomSelect .MuiFormLabel-root {
  font-size: 14px;
  -webkit-transform: translate(14px, 10px) scale(1);
  -moz-transform: translate(14px, 10px) scale(1);
  -ms-transform: translate(14px, 10px) scale(1);
  transform: translate(14px, 10px) scale(1);
}

.CustomSelect .MuiFormLabel-root.Mui-focused,
.CustomSelect .MuiFormLabel-root.MuiFormLabel-filled {
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
}

.CustomSelect .MuiInputBase-root {
  font-size: 14px;
}

.CustomSelect .MuiInputBase-root .MuiSelect-select {
  padding: 9px 14px;
}
.menu_style .MuiPaper-root{
  min-width: 180px;
}
.react-datepicker-wrapper{
  display: unset !important;
}

.react-select-container{
  width: 200px;
}