.Header{
    background: #ff7a00;
    padding: 0px 51px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}

.Container {
    width: 1024px;
    margin: 0 auto;
}

.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    font-weight: 600;
    text-decoration: none;
    color:  #fff;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.HeadLoginBU{
    font-size: 15px !important;
    line-height: normal !important;
    display: block !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    color: #fff !important;
    padding: 8px 16px !important;
    width: 144px !important;
    border-radius: 8px !important;
    border: none !important;
    background: #ff8414 !important;
    margin-left: 10px !important;
    cursor: pointer !important;
    overflow: hidden !important;
    text-align: center !important;
}

.HeadLoginBU2{
    font-size: 15px !important;
    line-height: normal !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    color: #fff !important;
    padding: 8px 16px !important;
    border-radius: 8px !important;
    border: none !important;
    background: #FF7A00 !important;
    margin-left: 10px !important;
    cursor: pointer !important;
    overflow: hidden !important;
    text-align: center !important;
}
.Dropdown_ManuCus{
    color: rgba(0, 0, 0, 0.87);
    padding: 0px 20px;
}
.Dropdown_ManuCus2{
    color: rgba(0, 0, 0, 0.87) !important;
    padding: 6px 36px !important;
}
.svg_Cus{
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 0;
}