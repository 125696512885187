@import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&family=Barlow+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;300;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;800&display=swap');
a{
    text-decoration: none !important;
}
*, body {
    font-family: 'Noto Sans', sans-serif;
}
.signup_MainDiv{
    width: 100%;

}
.login_BannerSec{
    /* background-image: url("../img/logo_page_image.jpg"); */
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.login_BannerSec img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.login_FormSec{
    width: 50%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
}
.login_LohinForm{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    left: 50%;
    max-height: 100%;
    max-width: 330px;
    overflow: hidden;
    position: relative;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 100%;
}
.login_FormTitle{
    color: #000;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
}
form{
    width: 100%;
    padding-top: 8px;
}
.login_FormFieldHalf{
    width: 48%;
    margin-bottom: 15px;
}
.details{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding-left: 8px;
    line-height: 30px;
}
.login_FormLabel{
    position: relative;
}
.login_FormRow .login_FormLabel svg{
    position: absolute;
    width: 20px;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
    color: #909090;
}
.login_FormControl{
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    color: #000;
    display: block;
    font-size: 14px;
    font-weight: 400;
    height: 50px;
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    min-width: 150px;
    padding: 16px;
    width: 100%; 
    outline: none;
}
.login_ErrorM{
    color: #f44336;
    font-size: 12px;
    margin: 0;
    margin-top: 4px;
    padding-left: 8px;
}
.Forgot_PasswordArea{
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 400;
    color: #909090;
    margin: 0;
}
.buttonGroup{
    display: flex;
    gap: 8px;
    padding-top: 8px;
}
.loginButton{
    background: #FF4C00;
    border: none;
    border-radius: 8px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    overflow: hidden;
    padding: 8px 16px;
    position: relative;
    transition: all .3s ease-in-out;
    width: 100%;
}
.signUpButton{
    background: #E2E2E2;
    border: none;
    border-radius: 8px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    overflow: hidden;
    padding: 8px 16px;
    position: relative;
    transition: all .3s ease-in-out;
    width: 100%;
    text-align: center;
}

.tabs{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.tabs_label{
    padding: 8px 16px !important;
    border-bottom: 2px solid #D4D4D4 !important;
    line-height: 1.5 !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    color: #909090 !important;
    text-transform: inherit !important;
}
.tabs_radio{
    display: none;
}
.tabs_content{
    order: 1;
    width: 100%;
}

@media (min-width: 360px){
    .login_BannerSec{
        display: none;
    }
    .login_FormSec{
        width: 100%;
    }
    .login_LohinForm{
        max-width: 330px;
        padding: 50px 0;
    }
    .login_FormFieldHalf{
        width: 100%;
    }
    .login_FormTitle{
        text-align: center;
    }
    .login_formSmTitle{
        text-align: center;
    }
}
@media (min-width: 576px){}
@media (min-width: 768px){}
@media (min-width: 992px){
    .login_BannerSec {
        display: block;
    }
    .login_FormSec {
        width: 50%;
    }
    .login_FormTitle{
        text-align: center;
    }
}
@media (min-width: 1200px){}
@media (min-width: 1366px){}
@media (min-width: 1400px){}
@media (min-width: 1600px){}
@media (min-width: 1800px){}


