.Container {
    width: 1024px;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.noData_FoundSec{
    display: flex;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgba(0,0,0,.16);
    height: 350px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
}
.noData_Found_SecUnder{
    align-content: center;
    opacity: .4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.noData_Found_SecUnder img{
    margin-bottom: 40px;
    width: 120px;
}
.noData_Found_SecUnder p{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}
.MainSec_Area{
    margin-top: 24px;
    min-height: 455px;
}
.BigDiv_Sec_UnderBg{
    display: flex;
    padding: 16px 16px 50px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    margin-top: 3px;
    min-height: 450px;
}
.table{
    width: 100%;
}
.table tr{
    border-bottom: 1px solid #D9D9D9;
}
.th_Heading{
    font-size: 14px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.td_SLNo{
    display: flex;
    width: 40px;
    height: 28px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 17px;
    color: #909090;
    font-weight: 600;
}
.td_Group{
    display: flex;
    gap: 8px;
}
.blueTex{
    font-size: 12px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.blackTex{
    font-size: 12px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.align_End{
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}
.group_Icon{
    display: flex;
    width: 38px;
    height: 38px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: #FFE1D8;
    border: none;
}
.style_non{
    border: none;
}
.group_Icon svg{
    width: 15px;
    height: 15px;
    color: #F00;
}

.search_Icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}
.searchTex{
    font-size: 11px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.button_Group{
    display: flex;
    gap: 8px;
}
.button_Cus{
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    height: 45px;
    width: 280px;
}
.button_Cus2{
    background: #D0F7FF;
    color: #174FB9;
}
.ending_BUDiv{
    display: flex;
    padding: 16px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    margin-top: 8px;
}
.ending_BU{
    display: flex;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
}

.halfBig_Div{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}

.Big_DivHeading{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}

.borderSec{
    width: 496px;
    height: 1px;
    background: #D9D9D9;
}

.ansLabel{
    height: 25px;
    margin: 3px 0;
    padding-right: 5px;
}

.wrongAnsLabel{
    background-color: #F78585;
    color: #F70303;
    
} 

.correctAnsLabel{
    background-color: #98F1B2;
    color: #067527;
}

.ansLabel > span:nth-child(2){
    font-size: 13px;
}

.correctAnsLabel > span:nth-child(2){
    font-weight: 600;
}

.correctAnsLabel svg{
    color: #067527;
}

.wrongAnsLabel > span:nth-child(2){
    font-weight: 600;
}

.wrongAnsLabel svg{
    color: #F70303;
}
.remarkArea{
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: #D0F7FF;
    margin: 5px 0 5px 30px;
}
.Headingremark{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.subHeadingremark{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}