.HeaderMain {
    background: rgb(255, 255, 255);
    color: rgb(158, 158, 158);
    top: 0;
    left: auto;
    right: 0;
    position: sticky;
    z-index: 999;
}

.HeaderMainUnder {
    padding: 10px 0;
    box-shadow: 0 3px 10px rgb(62 85 120 / 7%);
    min-height: 64px;
    display: flex;
    position: relative;
    align-items: center;
}

.HeaderRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
}

.HeadLogoAdmin {
    width: auto;
    position: relative;
    padding-left: 30px;
}

.HeadLogoAdmin img {
    max-width: 220px;
    height: 58px;
    object-fit: contain;
    object-position: center;
}

.HeadRightSec {
    width: auto;
    position: relative;
    padding-right: 15px;
}

.HeadBU {
    padding: 11px 13px;
    position: relative;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    transition: all .3s ease-in-out;
}

.HeadBU:hover {
    background-color: rgba(0, 0, 0, 0.06);
}

.HeadBU svg {
    width: 22px;
    height: 22px;
    stroke-width: 1.5;
    color: #9e9e9e;
}

.HeadBUCount {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #7c4836;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 4px;
    right: 4px;
    color: #fff;
}