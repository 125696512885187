.Container {
    width: 1024px;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.general_FormBoxArea{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
    margin-top: 20px;
}
.create_AccomplishmentSec{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    margin-top: 16px;
    margin-bottom: 8px;
}
.create_AccomplishmentSec p{
    font-size: 18px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}