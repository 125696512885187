.Container {
    width: 1140px;
    margin: 0 auto;
}
.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    /* display: block; */
    font-weight: 600;
    text-decoration: none;
    color: #000;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.HeadLoginBU{
    font-size: 15px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 8px 16px;
    width: 144px;
    border-radius: 8px;
    border: none;
    background: #FF7A00;
    margin-left: 10px;
    cursor: pointer;
    overflow: hidden;
}
.Topic_TestsMainArea{
    background: #174FB9;
    /* width: 1024px; */
    padding: 24px 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 13px;
}
.Topic_TestsTitleSmall{
    font-size: 14px;
    color: #FFF;
    font-weight: 500;
    text-align: center;
    margin: 0;
}
.Topic_TestsTitleBig{
    font-size: 20px;
    color: #FFF;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding-bottom: 16px;
}
.Topic_TestsCusRow{
    display: flex;
}
.Topic_TestsButtom{
    display: flex;
    /* width: 92px; */
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    gap: 3px;
}
.Topic_TestsButtom svg{
    color: #909090;
    width: 20.4px;
    height: 23px;
}
.Topic_SubjectButtom{
    display: flex;
    /* width: 92px; */
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    gap: 16px;
}
.Topic_SearchButtom{
    display: flex;
    /* width: 92px; */
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 16px;
    color: #909090;
    font-weight: 400;
    gap: 16px;
    padding-left: 40px;
}
.Topic_SubjectButtom svg{
    color: #909090;
    width: 20.4px;
    height: 23px;
}
.margin_right{
    margin-right: 8px;
}
.searchIcon{
    position: relative;
}
.searchIcon svg{
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
    color: #909090;
}
.SubjectHalf{
    width: 100%;
    padding: 6px;
}
.SubjectCont{
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-left: 2px solid #FFC077;
    background: #FEFFCA;
}
.pink_Colr{
    border-left: 2px solid #FF9E9E;
    background: #FFEDED;
}
.blue_Colr{
    border-left: 2px solid #6AB8FF;
    background: #EDF2FF;
}
.orange_Colr{
    border-left: 2px solid #FF9736;
    background: #FEFFCA;
}
.green_Colr{
    border-left: 2px solid #C9FF9E;
    background: #ECFFE5;
}
.subj_CircleSec{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.the_Circle{
    width: 36px;
    height: 36px;
    border-radius: 168px;
    background: #FFC077;
}
.Pink_Colr{
    background: #FF9E9E;
}
.Blue_Colr{
    background: #6AB8FF;
}
.Orange_Colr{
    background: #FFC077;
}
.Green_Colr{
    background: #C9FF9E;
}
.subj_Name{
    font-size: 18px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
table{
    width: 100%;
}
td,th{
    padding: 8px;
}
.th_HedingCustom{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
}
.td_ColorBlu{
    font-size: 13px;
    color: #0085FF;
    font-weight: 600;
}