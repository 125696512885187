
.Container {
    width: 1024px;
    margin: 0 auto;
}
.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    /* display: block; */
    font-weight: 600;
    text-decoration: none;
    color: #000;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.HeadLoginBU{
    font-size: 15px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 8px 16px;
    width: 144px;
    border-radius: 8px;
    border: none;
    background: #FF7A00;
    margin-left: 10px;
    cursor: pointer;
    overflow: hidden;
}
.Topic_TestsMainArea{
    background: #174FB9;
    /* width: 1024px; */
    padding: 24px 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 13px;
}
.Topic_TestsTitleSmall{
    font-size: 14px;
    color: #FFF;
    font-weight: 500;
    text-align: center;
    margin: 0;
}
.Topic_TestsTitleBig{
    font-size: 20px;
    color: #FFF;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding-bottom: 16px;
}
.Topic_TestsCusRow{
    display: flex;
}
.Topic_TestsButtom{
    display: flex;
    /* width: 92px; */
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    gap: 3px;
}
.Topic_TestsButtom svg{
    color: #909090;
    width: 20.4px;
    height: 23px;
}
.Topic_SubjectButtom{
    display: flex;
    /* width: 92px; */
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    gap: 16px;
}
.Topic_SearchButtom{
    display: flex;
    /* width: 92px; */
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 16px;
    color: #909090;
    font-weight: 400;
    gap: 16px;
    padding-left: 40px;
}
.Topic_SubjectButtom svg{
    color: #909090;
    width: 20.4px;
    height: 23px;
}
.margin_right{
    margin-right: 8px;
}
.searchIcon{
    position: relative;
}
.searchIcon svg{
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
    color: #909090;
}
.main_DivSec{
    float: left;
    width: 100%;
    margin-top: 5px;
}
.mainDivSec_SmallSide{
    float: left;
    position: sticky;
    /* top: 100px; */
    width: 20%;
}
.mainDivSec_SmallSideCard{
    display: flex;
    /* width: 224px; */
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 16px; */
    border-radius: 8px;
    border: 2px solid #EEE;
    background: #FFF;
}
.mainDivSec_SmallSideTitleArea{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid #D9D9D9;
}
.mainDivSec_SmallSideTitle{
    font-size: 18px;
    color: #000;
    font-weight: 500;
    margin: 0;
    padding-bottom: 12px;
}
.mainDivSec_SmallSideTitleRedColr{
    font-size: 16px;
    color: #f00;
    font-weight: 500;
    margin: 0;
}
.mainDivSec_ButtomFieldHalf{
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
}
.details{
    font-size: 13px;
    color: #000;
    font-weight: 500;
    padding-left: 2px;
}
.mainDivSec_ButtomArea{
    display: flex;
    /* width: 92px; */
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    gap: 85px; 
    margin-top: 10px;
}
.mainDivSec_ButtomArea svg{
    color: #909090;
    width: 20.4px;
    height: 23px;
}
.mainDivSec_Range{
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
.select-btn{
    display: flex;
    /* width: 92px; */
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    cursor: pointer;
}
.select-btn i{
    font-size: 25px;
    transition: 0.3s;
    color: #909090;
}
.select-menu.active .select-btn i{
    transform: rotate(-180deg);
}
.select-menu .options{
    position: relative;
    padding: 20px;
    margin-top: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    display: none;
}
.select-menu.active .options{
    display: block;
}
.options .option{
    display: flex;
    height: 55px;
    cursor: pointer;
    padding: 0 16px;
    border-radius: 8px;
    align-items: center;
    background: #fff;
}
.options .option:hover{
    background: #F2F2F2;
}
.option i{
    font-size: 25px;
    margin-right: 12px;
}
.option .option-text{
    font-size: 18px;
    color: #333;
}
.mainDivSec_BigSide{
    float: right;
    width: 79%;
}
.mainDivSec_BigSideCard{
    display: flex;
    /* width: 704px; */
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    margin-bottom: 8px;
    cursor: pointer;
}
.bigTitle{
    font-size: 16px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.smallTitle{
    font-size: 12px;
    color: #535353;
    font-weight: 500;
    margin: 0;
}
.menutitle{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
    padding-left: 6px;
}
.BigrightSideCardTitelSec{
    display: flex;
    flex-direction: column;
}
.BigSideCardTitelSec{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.BigrightSideCardTitelSec svg{
    color: #f00;
    width: 20px;
}
.GroupsSec{
    display: flex;
    gap: 16px;
}
.questionsSec{
    display: flex;
    align-items: center;
}
.iconcds{
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
.numberTex{
    font-size: 15px;
    color: #060710;
    font-weight: 600;
    margin: 0;
    padding-right: 8px;
}
.numberTex1{
    font-size: 15px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.TextTex{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.timesSec{
    display: flex;
    align-items: baseline;
}
.timesSec svg{
    width: 20px;
    height: 20px;
    color: #BBB;
    margin-right: 8px;
}

.noData_FoundSec{
    display: flex;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgba(0,0,0,.16);
    height: 450px;
    padding: 30px;
    position: relative;
    margin-top: 3px;
}
.noData_Found_SecUnder{
    align-content: center;
    opacity: .4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.noData_Found_SecUnder img{
    margin-bottom: 40px;
    width: 120px;
}
.noData_Found_SecUnder p{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}