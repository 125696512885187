.Container {
    width: 90%;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.signup_MainDiv{
    width: 100%;
}
.Header{
    background: #FF4C00;;
    padding: 0px 51px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    /* display: block; */
    font-weight: 600;
    text-decoration: none;
    color:  #fff;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.main_DivSec{
    padding-top: 16px;
}
.mainDivSec_Smalldiv{
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
}
.classHader{
    font-size: 14px;
    color: #FF4C00;
    font-weight: 600;
    margin: 0;
    padding-bottom: 6px;
}
.titleForHeding{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    padding-bottom: 8px;
}
.Subject{
    padding: 4px 8px;
    border-radius: 40px;
    background: #FFE1D8;
    font-size: 11px;
    color: #000;
    font-weight: 500;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    width: 60px;
}
.by_Quizzer{
    font-size: 11px;
    color: #909090;
    font-weight: 500;
    margin: 0;
}
.mainDivSec_Bigdiv{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    background: #FFF;
    border-radius: 8px;
    margin-top: 8px;
}
.details_Area{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.details_AreaHeding{
    font-size: 17px;
    color: #000;
    font-weight: 500;
    margin: 0;
}
.details_AreaClassSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}
.ClassSecHeading{
    font-size: 14px;
    color: #646464;
    font-weight: 600;
    margin: 0;
}
.ClassSecHeading span{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.ClassSecGroupArea{
    display: flex;
    align-items: center;
    gap: 8px;
}
.massgLogo{
    border-radius: 8px;
    background: #D0F7FF;
    display: flex;
    padding: 10px;
    align-items: flex-start;
}
.incosCK{
    border-radius: 8px;
    background: #D0F7FF;
    display: flex;
    padding: 10px;
    align-items: flex-start;
}
.incosCK svg{
    color: #fff;
    width: 18px;
    height: 18px;
}
.GroupAreaSmallTitel{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.GroupAreaBigTitel{
    font-size: 15px;
    color: #060710;
    font-weight: 700;
    margin: 0;
}
.leftSide_Div{
    display: inline-flex;
    align-items: flex-start;
    gap: 16px;
    min-height: 380px;
}
.halfSmall_Div{
    display: flex;
    /* width: 144px; */
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: #FFF;
}
.Question1Tex{
    font-size: 14px;
    color: #606060;
    font-weight: 500;
    margin: 0;
}
.NotCompletedTex{
    font-size: 11px;
    color: #606060;
    font-weight: 500;
    margin: 0;
}
.NotCompletedTex span{
    font-size: 13px;
    color: #606060;
    font-weight: 600;
    margin: 0;
}
.halfSmall_DivGroup{
    display: flex;
    align-items: center;
    gap: 6px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 8px;
}
.halfSmall_DivGroup p{
    font-size: 14px;
    color: #606060;
    font-weight: 500;
    margin: 0;
}
.spanCus p{
    font-size: 11px;
    color: #F00;
    font-weight: 600;
    margin: 0;
}
.spanCus{
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 40px;
    background: #FFE1D8;
}
.spanCus svg{
    color: #F00;
    width: 10px;
    height: 14px;
}
.spanCusp{
    font-size: 11px;
    color: #F00;
    font-weight: 600;
    margin: 0;
}
.halfSmall_DivGroup2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}
.halfSmall_DivGroup2 svg{
    width: 16px;
    height: 24px;
    color: #060710;
}
.halfSmall_DivGroup2 p{
    font-size: 14px;
    color: #2C2A2A;
    font-weight: 500;
    margin: 0;
}
.halfBig_Div{
    display: flex;
    width: 835px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}
.halfBig_Div p{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.borderSec{
    width: 778px;
    height: 1px;
    background: #D9D9D9;
}
.form_Box label input{
    display: none;
}
.form_Box label{
    display: block;
    cursor: pointer;
    margin-bottom: 12px;
    padding-left: 35px;
    position: relative;
}
.form_Box label .circle{
    width: 22px;
    height: 22px;
    border: 2px solid #49454F;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 3px;
    transition: border-color .5s ease;
}
.form_Box label .circle::before{
    content: "";
    width: 14px;
    height: 14px;
    background-color: #174FB9;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    box-sizing: border-box;
    margin-left: 2px;
    margin-top: 2px;
    transition: transform .5s ease;
    transform: scale(0);
}
.form_Box label span{
    color: #606060;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 28px;
    display: inline-block;
}
.form_Box label input:checked + .circle{
    border-color: #174FB9;
}
.form_Box label input:checked + .circle::before{
    transform: scale(1);
}
.halfBig_DivBu_Group{
    display: flex;
    width: 215px;
    justify-content: space-between;
}
.halfBig_DivBu{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #D0F7FF;
    font-size: 11px;
    color: #191D63;
    font-weight: 600;
    border: none;
}
.rightSide_Div{
    display: flex;
    /* width: 224px; */
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}
.rightSide_DivHeading{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}
.HeadingTex{
    font-size: 15px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.HeadingNum{
    font-size: 20px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.subHeading{
    font-size: 14px;
    color: #606060;
    text-align: center;
    font-weight: 500;
    margin: 0;
}
.calendar ul{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-align: center;
    padding-left: 0;
}
.calendar ul li{
    font-size: 14px;  
    color: #060710;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: #D6F8FF;
    padding: 8px 16px;
    margin: 2px;
}
.mainDivSec_Bigdiv1{
    margin-top: 9px;
}

.indexDiv{
    width: 182px;
    padding: 6px;
}

.indexSpan{
    border: 1px solid #5F5F5F;
    background: #FFFFFF;
    width: 26px;
    border-radius: 25% 25%;
    margin: 4px;
    text-align: center;
    float: left;
    padding: 0;
    color: #000;
}

.indexSpan.attempted{
    border: 1px solid #5F5F5F;
    background: #D0F7FF;
    color: #000;
}

.indexSpan.current{
    border: 1px solid #308321;
    background: #308321;
    color: #FFF;
}
.leftSide_Div2{
    display: inline-grid;
    align-items: flex-start;
    gap: 16px;
    background: #FFFFFF;
    padding: 13px 24px;
    border-radius: 8px;
    margin-left: 16px;
    justify-items: center;
}
.timer_Heading{
    color: #191D63;
    font-size: 15px;
    font-weight: 600;
}
.timer{
    text-align: center;
    color: #191D63;
    font-size: 20px;
    font-weight: 600;
}
.timer_Titel{
    text-align: center;
    color: #606060;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}
.Time_leftBU{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    border-radius: 8px;
    background: #174FB9;
    border: none;
    color: #FFFFFF;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
}