.Container {
    width: 90%;
    margin: 0 auto;
}
.background_Color{
    background: #E6ECF0;
    padding-bottom: 20px;
    min-height:100vh;
}
.Row{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.signup_MainDiv{
    width: 100%;

}
.Header{
    background: #FF4C00;;
    padding: 0px 51px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    /* display: block; */
    font-weight: 600;
    text-decoration: none;
    color:  #fff;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.top_DivSec{
    padding-top: 16px;
}
.mainDivSec_Smalldiv{
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
}
.classHader{
    font-size: 14px;
    color: #FF4C00;
    font-weight: 600;
    margin: 0;
    padding-bottom: 6px;
}
.titleForHeding{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    padding-bottom: 8px;
}
.Subject{
    padding: 4px 8px;
    border-radius: 40px;
    background: #FFE1D8;
    font-size: 11px;
    color: #000;
    font-weight: 500;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    width: 60px;
}
.by_Quizzer{
    font-size: 11px;
    color: #909090;
    font-weight: 500;
    margin: 0;
}
.mainDivSec_Bigdiv{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    background: #FFF;
    border-radius: 8px;
    margin-top: 8px;
}
.details_Area{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.details_AreaHeding{
    font-size: 17px;
    color: #000;
    font-weight: 500;
    margin: 0;
}
.details_AreaClassSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}
.ClassSecHeading{
    font-size: 14px;
    color: #646464;
    font-weight: 600;
    margin: 0;
}
.ClassSecHeading span{
    font-size: 14px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.ClassSecGroupArea{
    display: flex;
    align-items: center;
    gap: 8px;
}
.massgLogo{
    border-radius: 8px;
    background: #D0F7FF;
    display: flex;
    padding: 10px;
    align-items: flex-start;
}
.incosCK{
    border-radius: 8px;
    background: #D0F7FF;
    display: flex;
    padding: 10px;
    align-items: flex-start;
}
.incosCK svg{
    color: #fff;
    width: 18px;
    height: 18px;
    stroke-width: 3px;
    background: #174FB9;
}
.GroupAreaSmallTitel{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.GroupAreaBigTitel{
    font-size: 15px;
    color: #060710;
    font-weight: 700;
    margin: 0;
}
.your_ScoreArea{
    display: flex;
    width: 122px;
    height: 122px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 144px;
    background: #ACEBFF;
}
.your_ScoreHeading{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
    margin: 0;
    text-align: center;
}
.your_ScoreGroup{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.your_ScoreNum{
    font-size: 18px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.your_ScoreNumSub{
    font-size: 15px;
    color: #155E93;
    font-weight: 500;
    margin: 0;
}
.MainSec_Area{
    margin-top: 24px;
}
.BigDiv_Sec{
    width: 70%;
    padding: 8px 1%;
}
.BigDiv_Sec_UnderSmL{
    display: flex;
    padding: 8px 24px 8px 16px;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #FFF;
}
.UnderSmLContGroup{
    display: flex;
    align-items: center;
    gap: 16px;
}
.UnderSmLbigTitle{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.UnderSmLContGroup1{
    display: flex;
    align-items: center;
    gap: 24px;
}
.flex{
    display: flex;
    gap: 4px;
}
.UnderSmLTitleSMl{
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    margin: 0;
}
.UnderSmLTitleSMl{
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    margin: 0;
}
.BigDiv_Sec_UnderBg{
    display: flex;
    /* width: 623px; */
    /* height: 351px; */
    padding: 16px 16px 79px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    margin-top: 3px;
}
table{
    width: 100%;
}
.th_Heading{
    font-size: 13px;
    color: #4B4B4B;
    font-weight: 600;
    margin: 0;
}
.align_Right{
    text-align: right;
}
.td_SLNo{
    display: flex;
    width: 40px;
    height: 28px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    /* gap: 16px; */
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    font-size: 17px;
    color: #909090;
    font-weight: 600;
}
.blueTex{
    font-size: 12px;
    color: #191D63;
    font-weight: 600;
    margin: 0;
}
.blackTex{
    font-size: 12px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.td_Group{
    display: flex;
    gap: 8px;
}
.group_Icon{
    display: flex;
    width: 38px;
    height: 38px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: #FFE1D8;
}
.group_Icon svg{
    width: 15px;
    height: 15px;
    color: #F00;
}
.search_Icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}
.searchTex{
    font-size: 11px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.align_End{
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}
.mainTable tr{
    border-bottom: 1px solid #D9D9D9;
}
.mainTable td, .mainTable th {
    padding: 8px;
}
.SmallDiv_Sec{
    width: 30%;
    padding: 8px;
}
.SmallDiv_Sec_UnderBg{
    display: flex;
    /* width: 304px; */
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}
.group_Brod{
    border-bottom: 1px solid #D9D9D9;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 6px;
}
.SmallDivHeading{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.UnderBg_CostUL{
    margin: 0;
    padding: 0;
    width: 100%;
}
.UnderBg_CostUL li{
    display: flex;
    list-style: none;
    padding: 6px 0;
    justify-content: space-between;
}
.UnderBg_CostTitle{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}
.UnderBg_CostNo{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.View_AnalysisSec{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.View_Analysis_Grop{
    display: flex;
    align-items: center;
    gap: 8px;
}
.Analysis_GropTex{
    font-size: 14px;
    color: #174FB9;
    font-weight: 600;
    margin: 0;
}
.SmallDiv_Sec_UnderSmL{
    display: flex;

    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: inherit;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
    margin-top: 7px;
}
.startsHeading{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
input.star{
    display: none;
}
label.star{
    float: right;
    padding: 10px;
    font-size: 24px;
    color: #D9D9D9;
    transition: all .2s;
}
input.star:checked ~ label.star:before{
    content: '\f005';
    color: #f9be1e;
    transition: all .2s;
}
input.star_5:checked ~ label.star:before{
    color: #f9be1e;
    text-shadow: 0 0 20px #fba701;
}
input.star_1:checked ~ label.star:before{
    color: #F62;
}
label.star:hover{
    transform: rotate(-15deg) scale(1.3);
}
label.star:before{
    content: '\f006';
    font-family: FontAwesome;
}
.details{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    padding-left: 2px;
}
.revboxTextArea{
    display: flex;
    height: 46px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    font-size: 13px;
    color: #909090;
    font-weight: 400;
    width: 100%;
    resize: none;
    padding: 12px 0px 0px 12px;
    margin-top: 7px;
    outline: none;
}
.revboxButton{
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #174FB9;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    width: 100%;
    border: none;
    margin-top: 16px;
}

.halfBig_Div{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
}

.Big_DivHeading{
    font-size: 13px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}

.borderSec{
    width: 496px;
    height: 1px;
    background: #D9D9D9;
}
.form_Box label input{
    display: none;
}
.form_Box label{
    display: block;
    cursor: pointer;
    margin-bottom: 12px;
    padding-left: 35px;
    position: relative;
}
.form_Box label .circle{
    width: 22px;
    height: 22px;
    border: 2px solid #49454F;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 3px;
    transition: border-color .5s ease;
}
.form_Box label .circle::before{
    content: "";
    width: 14px;
    height: 14px;
    background-color: #174FB9;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    box-sizing: border-box;
    margin-left: 2px;
    margin-top: 2px;
    transition: transform .5s ease;
    transform: scale(0);
}
.form_Box label span{
    color: #606060;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 28px;
    display: inline-block;
}
.form_Box label input:checked + .circle{
    border-color: #174FB9;
}
.form_Box label input:checked + .circle::before{
    transform: scale(1);
}

.ansLabel{
    height: 25px;
    margin: 3px 0;
    padding-right: 5px;
}

.wrongAnsLabel{
    background-color: #F78585;
    color: #F70303;
    
} 

.correctAnsLabel{
    background-color: #98F1B2;
    color: #067527;
}

.ansLabel > span:nth-child(2){
    font-size: 13px;
}

.correctAnsLabel > span:nth-child(2){
    font-weight: 600;
}

.correctAnsLabel svg{
    color: #067527;
}

.wrongAnsLabel > span:nth-child(2){
    font-weight: 600;
}

.wrongAnsLabel svg{
    color: #F70303;
}

.remarkArea{
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: #D0F7FF;
    margin: 5px 0 5px 0;
}
.Headingremark{
    font-size: 14px;
    color: #060710;
    font-weight: 600;
    margin: 0;
}
.subHeadingremark{
    font-size: 12px;
    color: #060710;
    font-weight: 500;
    margin: 0;
}