.Container {
    width: 1024px;
    margin: 0 auto;
}
.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.Header{
    border-bottom: 1px solid #EAEAEA;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    /* display: block; */
    font-weight: 600;
    text-decoration: none;
    color: #000;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}

.HeadLoginBU{
    font-size: 15px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 8px 16px;
    width: 144px;
    border-radius: 8px;
    border: none;
    background: #FF7A00;
    margin-left: 10px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
}
.Explor_TestsMainArea{
    margin-top: 8px;
    background: #FF7A00;
    padding: 24px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 54px;

}
.Explor_TestsImgSec img{
    width: 167px;
    height: 147px;
    flex-shrink: 0;
}
.Explor_TestsTitelBig{
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding-bottom: 8px;
}
.Explor_TestsTitelBig1{
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin: 0;
    text-align: center;
    padding-bottom: 8px;
}
.Explor_TestsTitelSmall{
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    margin: 0;
    text-align: center;
    padding-bottom: 16px;
}
.Explor_TestsLoginBU{
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #000;
    padding: 8px 16px;
    width: 144px;
    border-radius: 8px;
    border: none;
    background: #FFF;
    margin: 0 auto;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
}
.CreateA_QuizMainArea{
    display: flex;
    padding: 16px 72px 24px 72px;
    justify-content: center;
    align-items: center;
    gap: 80px;
    border-radius: 8px;
    background: #FFF;
}
.CreateA_QuizTextSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
.CreateA_QuizTitel{
    font-size: 22px;
    color: #000;
    font-weight: 600;
    margin: 0;
}
.CreateA_QuizButton{
    display: flex;
    align-items: flex-start;
    gap: 24px;
}
.CreateA_QuizLoginBU{
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 6px 32px;
    border-radius: 4px;
    border: none;
    background: #FF7A00;
    margin: 0 auto;
    cursor: pointer;
    overflow: hidden;
}
.Topic_SpecificMainArea{
    display: flex;
    width: 100%;
    padding: 16px 24px;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    border: 2px solid #EAEAEA;
    background: #FFF;
}
.Topic_SpecificHeading_ButtomSec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.Topic_SpecificTitelSmall{
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin: 0;
}
.Topic_SpecificTitelBig{
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin: 0;
}
.Topic_SpecificLoginBU{
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    padding: 6px 32px;
    border-radius: 4px;
    border: none;
    background: #FF7A00;
    cursor: pointer;
    overflow: hidden;
}
.Topic_SpecificCard{
    width: 20%;
    padding: 8px 1%;
}
.Topic_Specific_ClassList{
    display: flex;
    padding: 24px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: #FFE7B8;
}
.Topic_Specific_ClassNub{
    font-size: 16px;
    color: #000;
    font-weight: 700;
    margin: 0;
}
.Topic_Specific_ClassTex{
    font-size: 12px;
    color: #413F3D;
    font-weight: 500;
    margin: 0;
}
.greenColr{
    background: #DBFFB8;
}
.blueColr{
    background: #B8EEFF;
}
.purpleColr{
    background: #D7B8FF;
}
.yelloColr{
    background: #FFE7B8;
}
.viewAll_Sec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
}
.viewAll_LeftSideText{
    font-size: 15px;
    color: #000;
    font-weight: 500;
    margin: 0;
}
.viewAll_RightSideText{
    font-size: 12px;
    color: #000;
    font-weight: 600;
    margin: 0;
}
.lab_CardSec{
    width: 25%;
    padding: 15px 1%;
}
.labCardArea{
    display: flex;
    padding: 24px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 2px solid #EAEAEA;
    background: #FFF;
}
.labCardText{
    font-size: 13px;
    color: #000;
    font-weight: 600;
    margin: 0;
}
.disflex{
    display: flex;
    align-items: baseline;
}
.disflex svg{
    width: 20px;
    color: #000 !important;
    text-decoration: none !important;
}
.SolutionsMainArae{
    width: 25%;
    padding: 15px 1%;
}
.SolutionsCont{
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 40px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #B8EEFF;
    cursor: pointer;
}
.SolutionsTextBig{
    font-size: 14px;
    color: #000;
    font-weight: 600;
    margin: 0;
}
.SolutionsTextsmall{
    font-size: 12px;
    font-weight: 400;
    color: #000;
    margin: 0;
}
.roundSec{
    width: 64px;
    height: 64px;
    border-radius: 168px;
    background: #FFF;
}
.Explore_EndingHalf{
    width: 50%;
}
.Explore_EndingCusRow{
    display: flex;
    flex-wrap: wrap;
}
.Explore_EndingFeild{
    width: 46%;
    padding: 15px 2%;
}
.Explore_EndingMainCont{
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 8px;
    border: 2px solid #00B2FF;
    background: #B8EEFF;
    flex-direction: column;
}
.classText{
    font-size: 12px;
    color: #006DD2;
    font-weight: 700;
    margin: 0;
    padding-bottom: 9px;
}
.subjectText{
    font-size: 13px;
    color: #000;
    font-weight: 600;
    margin: 0;
    padding-bottom: 9px;
}
.subjectMiniText{
    font-size: 12px;
    color: #413F3D;
    font-weight: 500;
    margin: 0;
}
.Explore_EndingButton{
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #000;
    font-size: 12px;
    color: #FFF;
    font-weight: 700;
    border: none;
    width: 90px;
    margin-top: 20px;
}
.yelloColr__Pro{
    background: #FFE7B8;
    border: 2px solid #FF5C00;
}  
.purpleColr__Pro{
    border: 2px solid #D200BD;
    background: #F8CDFF;
}
.greenColr__Pro{
    border: 2px solid #00D23B;
    background: #D4FFCD;
}
.colrOreng__Txt{
    color: #FF5C00;
}
.colrpurple__Txt{
    color: #D200BD;
}
.colrgreen__Txt{
    color: #00D23B;
}
.Explore_EndingHalfMainCont{
    display: flex;
    /* width: 452px; */
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    padding: 20px 8px;
}
.Explore_EndingHalfMainContText{
    font-size: 32px;
    color: #000;
    font-weight: 400;
    text-align: right;
}
.Explore_EndingHalfMainCont img{
    width: 290px;
}