
.signup_MainDiv{
    width: 100%;

}
.Header{
    background: #FF4C00;;
    padding: 0px 51px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}
.HeadRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Menu{
    display: flex;
    align-items: center;
}
.Menu ul {
    display: flex;
    margin: 0;
}
.Menu ul li {
    list-style: none;
}
.Menu ul li a{
    font-size: 15px;
    line-height: 60px;
    /* display: block; */
    font-weight: 600;
    text-decoration: none;
    color:  #fff;
    padding: 10px 10px;
    position: relative;
    transition: all .3s ease-in-out;
}
.login_BannerSec{
    background-image: url("../../../../public/static/img/sign_page_image.jpg");
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.login_FormSec{
    width: 50%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
}
.login_LohinForm{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    left: 50%;
    max-height: 100%;
    max-width: 330px;
    overflow: auto;
    position: relative;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 100%;
}
.login_FormTitle{
    color: #000;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 8px;
    width: 100%;
    border-bottom: 3px solid #FF6B00;
    padding-bottom: 9px;
    padding-left: 8px;
}
form{
    width: 100%;
}
.login_FormFieldHalf{
    width: 48%;
    margin-bottom: 8px;
}
.details{
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    display: flex;
    padding-left: 8px;
    padding-bottom: 8px;
}
.login_FormLabel{
    position: relative;
}
.login_FormRow .login_FormLabel svg{
    position: absolute;
    width: 20px;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
    color: #909090;
}
.login_FormControl{
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    color: #909090;
    display: block;
    font-size: 13px;
    font-weight: 400;
    height: 38px;
    line-height: 1.5;
    min-width: 150px;
    padding: 16px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 330px;
    outline: none;
}
.Forgot_PasswordArea{
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 400;
    color: #000;
    text-decoration-line: underline;
}
.buttonGroup{
    display: flex;
    gap: 8px;
    margin-top: 16px;
}
.loginButton{
    background: #174FB9;
    border: none;
    border-radius: 8px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    overflow: hidden;
    padding: 9px;
    position: relative;
    transition: all .3s ease-in-out;
    width: 100%;
}
.login_ErrorM{
    color: #f44336;
    font-size: 12px;
    margin: 0;
    margin-top: 4px;
    padding-left: 8px;
}

@media (min-width: 360px){
    .login_BannerSec{
        display: none;
    }
    .login_FormSec{
        width: 100%;
    }
    .login_LohinForm{
        max-width: 330px;
        padding: 100px 0;
        overflow: hidden;
    }
    .login_FormFieldHalf{
        width: 100%;
    }
    .login_formSmTitle{
        text-align: center;
    }
}
@media (min-width: 576px){}
@media (min-width: 768px){}
@media (min-width: 992px){
    .login_BannerSec {
        display: block;
    }
    .login_FormSec {
        width: 50%;
    }
}
@media (min-width: 1200px){}
@media (min-width: 1366px){}
@media (min-width: 1400px){}
@media (min-width: 1600px){}
@media (min-width: 1800px){}


